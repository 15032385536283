.page-banner {
  position: relative;

  h1 {
    top: 40%;
  }
}

// Board page bios
.bio-photos--item {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.bio-modal-holder {
  max-width: 600px;

  @media (orientation: landscape) {
    max-width: 80vw;
  }
  
  @include media-breakpoint-up(lg){
    min-width: 80vw;
    max-width: 80vw;
  }

  @include media-breakpoint-up(xl){
    min-width: 66vw;
    max-width: 66vw;
  }


  .bio-grid {
    display: grid;
    gap: 16px;

    @media (orientation: landscape) {
      grid-template-columns: 1fr 3fr;
    }

    @include media-breakpoint-up(lg){
      grid-template-columns: 1fr 3fr;
    }
  }
}

#home_carousel {
  overflow: hidden;
}

// carousel video embed 
// Container for the video background
.vid-iframe-background {
  position: absolute;
  width: 100%;
  // height: 100vh; // Full viewport height
  overflow: hidden;
  // box-shadow: inset -2px 0px 0px 0px #0050FF;
  z-index:1 !important;

  iframe {
    position: absolute;
    margin-right: -1px;
    top: 50%;
    left: 50%;
    width: 100%;
    // height: 100vh;
    transform: translate(-50%, -50%);
    // pointer-events: none; // Makes the iframe non-interactive
    // z-index: -1;
    z-index: -1;
  }
}

.carousel-indicators {
  [data-bs-target] {
    background-color: $brand-primary;
  }
}

// home page video
#hp_video {
  // position: relative;
  width: 100%;
  // height: 100vh; // Full viewport height
  overflow: hidden;

  // @extend .full-width;
  // height: calc(100vh - #{$navigation-height-sm});
  // height: 0;
  // padding-bottom: 50%;
  // padding-bottom: 56.25%;

  // @include media-breakpoint-up(lg){
  //   height: calc(100vh - #{$navigation-height} - 32px);
  // }

  video {
    // Simulationg background-size: cover
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  .video-controls {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    flex-flow: row nowrap;

    @include media-breakpoint-up(lg){
      bottom: 32px;
      right: 32px;
    }

    & > div:first-of-type {
      margin-right: 8px;
    }

    .media-btn {
      width: 24px;
      height: 24px;
      opacity: 0.66;
      @include transition(all 0.2s ease);
      
      @include media-breakpoint-up(lg){
        width: 34px;
        height: 34px;
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    
    .play-btn,
    .unmute-btn {
      display: none;
    }
  }
}