// Search form
.search-form {
  // @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  // @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
