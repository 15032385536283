/* ==================================================
   Fonts
   ================================================== */

@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Founders Grotesk';
  src: local('Founders Grotesk'),
      url('../fonts/founders-grotesk-light-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

/* ==================================================
   Base
   ================================================== */
body {
  // font-family: $font-stack;
}




/* ==================================================
   Links
   ================================================== */
a { 
  outline: none; 
  border: 0;
  color: $blue;
  // font-weight: 700;
  text-decoration: none;
  @include transition(all .5s ease);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: $gray;
    // text-decoration: underline;
  }
}

.social-icons li a .webicon {
   color: $blue;
}


/* ==================================================
   Typography
   ================================================== */

.h1, .h2, .h3, .h4, .h5, .h6, 
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: $font-stack;
}

/* ==================================================
   Images
   ================================================== */

.img-attr {
  
  .inline-credit {
    top: unset;
    bottom: 0 !important;
    left: 0 !important;
    width: auto !important;
  }
}

// .wrap figure,
// .wrap img {
//   max-width: 100%;
//   height: auto;
// }

// .ratio>* {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100% !important;
//     height: 100% !important;
// }

