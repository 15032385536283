// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// ==========================================================================
//   Vars
// ==========================================================================

$brand-primary: #62b5e5;
$blue:          $brand-primary;
$gray:          #d0d3d4;
$tan:           #a09074;
$gold:          #ffa40d;



// $red:           #fa4132;
$red:           $brand-primary;



$green:         #48896f;
$black:         #1c1f29;
$white:         #ffffff;

// constrast ratio override
$min-contrast-ratio: 2.5;


// fonts
// $base-font-size:  16px; // Henceforth known as 1em // ping
// $font-stack:      'Architype', Arial, sans-serif;
$font-stack:      'Founders Grotesk', Arial, sans-serif;
$sans-serif:      'Architype', Arial, sans-serif;
// $sans-serif:      'Arial', sans-serif;
$serif:           'Times New Roman', Georiga, serif;
// $icon-font-path:  "../fonts/"; // Glyphicons font path
$font-path: "../fonts" !default;

